<template>

	<a-card :bordered="false" class="dashboard-bar-line header-solid">
		<template #title>
			<h6>{{ datasetName }}</h6>			
			<p>than last week<span class="text-success">{{percentageChange}}%</span></p>	
		</template>
		<template #extra>
			<a-badge color="primary" class="badge-dot-primary" :text="datasetName" />
		
		</template>
		<chart-line :height="310" :data="lineChartData"></chart-line>
	</a-card>

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartLine from '../Charts/ChartLine' ;

	export default ({
		components: {
			ChartLine,
		},
		props:{
			dataLabels: Array,
			datasetName: String,
			data: Array,
			percentageChange: Number,
		},
		data() {
			return {

				// Data for line chart.
				lineChartData: {
					labels: this.dataLabels,
					datasets: [{
						label: this.datasetName,
						tension: 0.4,
						borderWidth: 0,
						pointRadius: 0,
						borderColor: "#1890FF",
						borderWidth: 3,
						data: this.data,
						maxBarThickness: 6

					},
					],
				},
			}
		},
	})

</script>